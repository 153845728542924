<template>
  <div class="uk-section-muted">
    <div class="uk-position-absolute">
      <div
        class="uk-position-top uk-navbar-sticky uk-position-fixed header"
        uk-sticky="animation: uk-animation-slide-top;"
      >
        <div class="uk-container">
          <img src="./Bamaq_Digital_Roxo.png" class="img-size" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" src="./Header.scss"></style>
<script>
export default {
  name: 'headerLP',
}
</script>
