<template>
  <section class="uk-position-relative uk-margin-xlarge-bottom">
    <div id="caracteristicas" />
    <div class="uk-container uk-visible@s">
      <div class="width-text">
        <span class="text-title tx-lg">CONHEÇA AGORA O <br /></span>
        <span class="text-title tx-lg-2">APLICATIVO DO CLIENTE <br /></span>
        <span class="text-title tx-lg-3">BAMAQ </span>
        <span class="text-title tx-lg">CONSÓRCIO! </span>
      </div>
      <div style="margin-top: -30px; padding-left: 50px">
        <span class="text-title-2"
          >Para oferecer mais comodidade, agilidade e<br />
          segurança criamos o app bamaq consórcio.<br />
          Com nosso aplicativo é possível fazer a gestão do<br />
          seu Consórcio na palma da sua mão.</span
        >
      </div>
      <div class="width-text-2 uk-margin-top">
        <a
          href="https://apps.apple.com/br/app/bamaq-cons%C3%B3rcio-app-do-cliente/id1477396855"
          target="_blank"
        >
          <img src="./Apple_Store_Branca.png" />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=org.orbbits.bmqcliente"
          target="_blank"
          class="uk-margin-left"
        >
          <img src="./Play_Store_Branca.png" />
        </a>
      </div>
      <div>
        <img class="img-position" src="./iPhone.png" />
      </div>
    </div>

    <div class="uk-container uk-hidden@s" style="padding: 20px">
      <div class="width-text-mobile">
        <span class="text-title-mobile">CONHEÇA AGORA O <br /></span>
        <span class="text-title-mobile-2">APLICATIVO DO CLIENTE <br /></span>
        <span class="text-title-mobile tx-purple-bamaq">BAMAQ </span>
        <span class="text-title-mobile">CONSÓRCIO! </span>
      </div>

      <div class="width-text-mobile-2" style="margin-top: -20px">
        <span class="text-title-mobile-3"
          >Para oferecer mais comodidade,<br />
          agilidade e segurança criamos o<br />
          app bamaq consórcio.<br />
          Com nosso aplicativo é possível<br />
          fazer a gestão do seu Consórcio<br />
          na palma da sua mão.</span
        >
      </div>

      <div class="width-text-mobile-4">
        <a
          href="https://apps.apple.com/br/app/bamaq-cons%C3%B3rcio-app-do-cliente/id1477396855"
          target="_blank"
        >
          <img
            class="img-mobile-store"
            style="margin-left: 9px"
            src="./Apple_Store_Branca.png"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=org.orbbits.bmqcliente"
          target="_blank"
        >
          <img
            class="img-mobile-store"
            style="margin-left: 2px"
            src="./Play_Store_Branca.png"
          />
        </a>
        <img class="img-position-mobile" src="./iPhone.png" />
      </div>
    </div>
  </section>
</template>
<style lang="scss" src="./TextBanner.scss"></style>
<script>
export default {
  name: 'TextBanner',
}
</script>
