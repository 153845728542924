<template>
  <section>
    <div class="uk-container">
      <div class="uk-text-center">
        <img src="./line_2.png" alt="" style="height: 4px" />
      </div>
      <div class="uk-text-center uk-margin-top">
        <h2 class="size-tx">
          <b>Por que</b>
          você deve<br />
          escolher a Bamaq Consórcio?
          <div class="txt-tl">
            Mais autonomia e praticidade na gestão do seu consórcio.
          </div>
        </h2>
      </div>
      <div
        class="uk-grid-collapse uk-child-width-expand@s uk-margin-top"
        uk-grid
      >
        <div>
          <div class="uk-padding margin-version-mb">
            <img
              class="uk-margin-bottom center-mobile mb-margin"
              src="./icon_1.png"
              alt=""
            />
            <div>
              <span class="uk-text-st mb-margin">
                Valor do seu próprio <br />
                <b>veículo como lance</b>
              </span>
              <div class="uk-margin-top mb-margin-2">
                <h1
                  class="uk-margin-top uk-text-sub uk-text-sub-mb center-mobile"
                >
                  Utilize o valor do seu veículo atual como lance para acelerar
                  a sua contemplação.
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-padding uk-padding-mobile">
            <img class="uk-margin-bottom mb-margin" src="./icon_2.png" alt="" />
            <div>
              <span class="uk-text-st">
                Pague a primeira parcela com <b>PIX, Boleto ou Cartão</b>
              </span>
              <div class="uk-margin-top">
                <span class="uk-margin-top uk-text-sub">
                  Para facilitar na hora do pagamento, a sua primeira parcela
                  pode ser paga por pix, boleto ou até mesmo cartão.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-padding uk-padding-mobile">
            <img class="uk-margin-bottom" src="./icon_3.png" alt="" />
            <div>
              <span class="uk-text-st">
                <b>3 meses </b>de carência após contemplação por lance
              </span>
              <div class="uk-margin-top">
                <span class="uk-margin-top uk-text-sub">
                  Quando contemplado por lance, o cliente terá uma carência de
                  90 dias para retomar os pagamentos das parcelas.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-padding uk-padding-mobile">
            <img class="uk-margin-bottom" src="./icon_4.png" alt="" />
            <div>
              <span class="uk-text-st">
                Utilize seu <b>FGTS </b>no<br />
                consórcio de imóveis
              </span>
              <div class="uk-margin-top">
                <span class="uk-margin-top uk-text-sub" id="avaliacoes">
                  Facilitando na sua conquista, é possível utilizar o seu FGTS
                  como lance para o consórcio de imóveis e aumentar as sua
                  chances.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" src="./BamaqChoice.scss"></style>
<script>
export default {
  name: 'BamaqChoiceFeatures',
}
</script>
