<template>
  <section>
    <div class="uk-container uk-margin-large-bottom">
      <div class="uk-margin-large-top">
        <MobileText />
        <div
          class="uk-grid-large uk-child-width-expand@s uk-text-center"
          uk-grid
        >
          <div>
            <img class="img-phone-mobile" src="./store.png" alt="" />
          </div>
          <div>
            <div class="uk-text-left">
              <div class="uk-visible@l">
                <div class="star">⭐⭐⭐⭐⭐</div>
                <div>
                  <h1 class="title-one">
                    Baixe
                    <span class="text-black"
                      >agora <span class="tx-mobile">mesmo.</span></span
                    >
                    <img class="arrow" src="./seta.png" alt="" />
                    <img class="line_4" src="./line_4.png" alt="" />
                  </h1>
                </div>
              </div>
              <div>
                <h1 class="title-two uk-visible@l">Disponível para download</h1>
              </div>
            </div>
            <div class="margin-mb-bt">
              <div class="uk-grid-small uk-center-mobile" uk-grid>
                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=org.orbbits.bmqcliente&hl=pt"
                    target="_blank"
                  >
                    <img
                      class="icon-play"
                      style="margin-right: 1px"
                      src="./GoogleBranca.png"
                      alt=""
                    />
                  </a>
                </div>
                <div>
                  <a
                    href="https://apps.apple.com/br/app/bamaq-cons%C3%B3rcio-app-do-cliente/id1477396855"
                    target="_blank"
                  >
                    <img
                      class="icon-play"
                      style="margin-right: 8px"
                      src="./AppleBranca.png"
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div class="uk-grid-small uk-center-mobile" uk-grid>
                <div>
                  <div class="qrcode">
                    <span
                      ><img
                        class="icon-end qrcode-bamaq"
                        src="./qrcode_bamaq_2.png"
                        alt=""
                    /></span>
                    <div>
                      <span class="qrcode-text"
                        ><img class="icon-end" src="./play.png" alt="" /> Baixar
                        agora
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="qrcode">
                    <span
                      ><img
                        class="icon-end qrcode-bamaq"
                        src="./qrcode_bamaq.png"
                        alt=""
                    /></span>
                    <div>
                      <span class="qrcode-text"
                        ><img class="icon-end" src="./apple.png" alt="" />
                        Baixar agora
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" src="./StoreApp.scss"></style>
<script>
import MobileText from './StoreAppTextMobile.vue'
export default {
  name: 'StoreAppFeatures',
  components: {
    MobileText,
  },
}
</script>
⭐
