<template>
  <div class="uk-section-muted">
    <hr class="line-mobile-footer uk-hidden@s" />
    <div class="uk-container background-color-footer">
      <nav class="uk-navbar-container background-color-footer">
        <div class="uk-container background-color-footer">
          <div class="uk-padding-small" uk-navbar>
            <div class="uk-navbar-left img-mobile-position">
              <ul class="uk-navbar-nav uk-visible@m">
                <img src="./Bamaq_Digital_Roxo.png" class="img-footer" alt="" />
              </ul>
            </div>
            <div class="uk-navbar-right text-footer text-footer-mobile">
              <div v-for="item in menuItems" :key="item.id">
                <a :href="item.link">{{ item.label }}</a>
              </div>
              <ul class="uk-navbar-nav uk-hidden@s">
                <img src="./Bamaq_Digital_Roxo.png" class="img-footer" alt="" />
              </ul>
              <div class="margin-social-midia">
                <a
                  v-for="(socialIcon, index) in socialIcons"
                  :key="index"
                  :href="socialLinks[index]"
                  target="_blank"
                  class="margin-social-midia"
                >
                  <img
                    :src="require(`./${socialIcon}.png`)"
                    class="icon-link"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<style lang="scss" src="./Footer.scss"></style>

<script>
export default {
  name: 'FooterLP',
  data() {
    return {
      menuItems: [
        { id: 1, label: 'Características', link: '#caracteristicas' },
        { id: 2, label: 'Sobre o App', link: '#aplicativo' },
        { id: 3, label: 'Diferenciais', link: '#diferenciais' },
        { id: 4, label: 'Avaliações', link: '#avaliacoes' },
        { id: 5, label: 'Download', link: '#download' },
      ],
      socialIcons: ['Insta', 'facebook', 'linkedin', 'youtube'],
      socialLinks: [
        'https://www.instagram.com/bamaq.digital/',
        'https://www.facebook.com/bamaqconsorcio/?locale=pt_BR',
        'https://www.linkedin.com/company/bamaq-consorcio/',
        'https://www.youtube.com/@bamaqdigital/featured',
      ],
    }
  },
}
</script>
