<template>
  <div class="uk-background-cover uk-hidden@s">
    <section uk-slideshow="ratio: 500:500; max-height: 400; max-width: 600">
      <div class="uk-position-relative uk-light" tabindex="-1">
        <ul class="uk-slideshow-items uk-margin-medium-top">
          <li>
            <img src="./fundo_mobile.png" style="width: 100%" uk-img="" />
          </li>
        </ul>
      </div>
    </section>
  </div>
  <div
    class="uk-background-cover uk-visible@m"
    style="margin-top: 0; background-position-y: 20px"
  >
    <section
      uk-slideshow="animation: fade; autoplay: false; ratio: 9:2; min-height: 700"
    >
      <div
        class="uk-position-relative uk-visible-toggle"
        tabindex="-1"
        style="margin-top: 80px"
      >
        <div class="uk-container">
          <div class="uk-slideshow-items">
            <li>
              <img src="./fundo_2.png" alt="" uk-cover />
            </li>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style lang="scss" src="./FirstComponents.scss"></style>
<script>
export default {
  name: 'FirstComponents',
}
</script>
