<template>
  <HeaderLP />
  <FirstComponent />
  <TextBanner />
  <BamaqFeatures />
  <AboutApp />
  <DigitalExp />
  <Assemblies />
  <BamaqChoice />
  <About />
  <StoreApp />
  <Footer />
</template>

<script>
import HeaderLP from './components/Header/Header.vue'
import FirstComponent from './components/FirstComponent/FirstComponent.vue'
import TextBanner from './components/TextBanner/TextBanner.vue'
import BamaqFeatures from './components/BamaqFeatures/BamaqFeatures.vue'
import AboutApp from './components/AboutApp/AboutApp.vue'
import DigitalExp from './components/DigitalExp/DigitalExp.vue'
import Assemblies from './components/Assemblies/Assemblies.vue'
import BamaqChoice from './components/BamaqChoice/BamaqChoice.vue'
import About from './components/About/About.vue'
import StoreApp from './components/StoreApp/StoreApp.vue'
import Footer from './components/Footer/Footer.vue'

export default {
  name: 'App',
  components: {
    HeaderLP,
    FirstComponent,
    TextBanner,
    BamaqFeatures,
    AboutApp,
    DigitalExp,
    Assemblies,
    BamaqChoice,
    About,
    StoreApp,
    Footer,
  },
}
</script>
