<template>
  <section>
    <div class="uk-margin-large-top uk-container">
      <div
        class="uk-grid-small uk-grid-match uk-child-width-expand@s uk-margin-left"
        uk-grid=""
      >
        <div class="uk-text-center uk-visible@s">
          <div class="uk-text-center">
            <div class="st-position st-position-mb">
              <img src="./seta.png" />
            </div>
            <div class="margin-top-st">
              <div>
                <img src="./value.png" />
              </div>
              <div>
                <span class="payment">Gerencie seus pagamentos</span>
              </div>
              <div>
                <h1 class="text-payment uk-margin-top">
                  Tenha acesso ao histórico <br />de pagamentos, segunda
                  <br />
                  via de boletos e próximas faturas.
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-text-center uk-visible@s">
          <div class="uk-width-expand@m uk-margin-bottom">
            <a class="uk-text-center">
              <img
                style="width: 800px; margin-left: 40px"
                src="./icon-dig.png"
                uk-img="uk-img"
              />
            </a>
          </div>
        </div>
        <div class="" style="margin-left: -50px">
          <div class="uk-text-center uk-visible@s">
            <div>
              <img src="./phone.png" />
            </div>
            <div>
              <span class="payment">Experiência Digital</span>
            </div>
            <div>
              <h1 class="text-payment uk-margin-top">
                Acompanhe a evolução do seu consórcio e conheça todos os
                detalhes de seu Grupo e Cota.
              </h1>
            </div>
          </div>
        </div>

        <div class="uk-hidden@s uk-margin-right">
          <div class="uk-text-right">
            <div class="st-position-mb">
              <img src="./seta.png" />
            </div>
            <div>
              <div>
                <img src="./phone.png" />
              </div>
              <div class="uk-margin-small-top">
                <span class="payment-mb">Experiência Digital</span>
              </div>
              <div class="uk-margin-small-top">
                <h1 class="text-payment-mb">
                  Acompanhe a evolução do seu <br />consórcio e conheça todos os
                  <br />
                  detalhes de seu Grupo e Cota.
                </h1>
              </div>
            </div>
          </div>
          <div class="uk-text-left">
            <div>
              <div>
                <img src="./value.png" />
              </div>
              <div class="uk-margin-small-top">
                <span class="payment-mb"
                  >Gerencie seus<br />
                  pagamentos</span
                >
              </div>
              <div class="uk-margin-small-top">
                <h1 class="text-payment-mb">
                  Tenha acesso ao histórico<br />
                  de pagamentos, segunda via<br />
                  de boletos e próximas faturas.
                </h1>
              </div>
            </div>
          </div>
          <div class="uk-margin-top">
            <a class="uk-text-center">
              <img
                style="width: 250px; margin-left: 40px"
                src="./icon-dig.png"
                uk-img="uk-img"
              />
            </a>
          </div>
          <div class="uk-text-center">
            <img class="uk-margin-line" src="./line_2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" src="./DigitalExp.scss"></style>
<script>
export default {
  name: 'DigitalExp',
  data() {
    return {}
  },
}
</script>
