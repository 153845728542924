<template>
  <div class="uk-hidden@m uk-margin-left">
    <div class="">⭐⭐⭐⭐⭐</div>
    <div>
      <h1 class="text-t1 uk-margin-small-top">
        <span class="text-t1-red">Baixe</span> agora mesmo.
        <img class="arrow" src="./seta.png" alt="" />
        <img class="line_4" src="./line_4.png" alt="" />
        <h1 class="text-t2">Disponível para download</h1>
      </h1>
    </div>
  </div>
</template>
<style>
.text-t1 {
  color: var(--grayscale-gray-3, #262626);
  font-family: Work Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.044px;
  width: 200px;
}
.text-t1-red {
  color: var(--main-colors-secondary-secondary, #f9154f);
}
.text-t2 {
  color: var(--grayscale-gray-0, #6f6f6f);
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.014px;
  margin-top: -30px;
}
</style>
