<template>
  <section>
    <div class="uk-margin-large-top uk-container uk-margin-large-bottom">
      <div class="" uk-grid>
        <div class="uk-width-2-3@m">
          <div class="uk-text-rigth">
            <div>
              <img class="uk-mrl title-h1-mb" src="./cwl.png" alt="" />
            </div>
            <div>
              <h1 class="title-h1 position-mb">Cruze os dedos</h1>
            </div>
            <div>
              <h1 class="title-h2 title-h2-mb">
                Faça oferta de lances e potencialize<br class="uk-visible@s" />
                suas chances de contemplação.
              </h1>
            </div>
            <div>
              <img
                class="uk-mrl-img uk-mrl-img-mb text-rt"
                src="./phone_2.png"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-3@m text-rt" style="margin-bottom: -30px">
          <div>
            <div>
              <img class="uk-mrl-img-mb-2" src="./phone_3.png" />
            </div>
            <div class="uk-margin-top margin-mb-sl">
              <img class="uk-mrl-2 uk-mrl-2-mb" src="./fone.png" alt="" />
            </div>
            <div>
              <h1
                class="title-h2-2 uk-margin-top title-h2-mb uk-mrl-mb text-rt"
                id="diferenciais"
              >
                Fique por dentro<br />
                das Assembleias
              </h1>
            </div>
            <div>
              <h1 class="title-h2-3 title-h2-3-mb">
                Veja o cronograma mensal de assembleias e os resultados no App.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" src="./Assemblies.scss"></style>
<script>
export default {
  name: 'AssembliesFeatures',
}
</script>
