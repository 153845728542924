<template>
  <section>
    <div class="uk-container">
      <h2
        class="uk-text-center margin-features"
        style="margin-top: -155px"
        id="aplicativo"
      >
        <span class="text-features-1 text-features-mb"
          >Características
          <span class="text-features-2 text-features-mb"> BAMAQ 🔥</span></span
        >
      </h2>
      <div>
        <div
          class="uk-visible@m uk-grid-small uk-grid-match uk-child-width-1-5@s uk-child-width-1-5@m"
          uk-grid=""
        >
          <div class="uk-text-center uk-margin-top">
            <div>
              <img src="./taxa.png" />
            </div>
            <div class="uk-margin-top uk-text-beneficio">
              <span class="text-taxa"
                ><span class="background-text">Melhor taxa</span> <br />
                do Brasil</span
              >
            </div>
          </div>
          <div class="uk-text-center uk-margin-top">
            <div>
              <img src="./pig.png" />
            </div>
            <div class="uk-margin-top uk-text-beneficio text-taxa">
              Parcelas que<br />
              <span class="background-text">cabem no bolso</span>
            </div>
          </div>
          <div class="uk-text-center uk-margin-top">
            <div>
              <img src="./money.png" />
            </div>
            <div class="uk-margin-top uk-text-beneficio text-taxa">
              Condições
              <span class="background-text">exclusivas</span>
              feitas para você
            </div>
          </div>
          <div class="uk-text-center uk-margin-top">
            <div>
              <img src="./pc.png" />
            </div>
            <div class="uk-margin-top uk-text-beneficio text-taxa">
              Experiência<br /><span class="background-text">
                100% digital</span
              >
            </div>
          </div>
          <div class="uk-text-center uk-margin-top">
            <div>
              <img src="./people.png" />
            </div>
            <div class="uk-margin-top uk-text-beneficio text-taxa">
              Mais de <span class="background-text">30 anos</span><br />
              de mercado
            </div>
          </div>
        </div>
      </div>
      <div class="uk-hidden@s uk-grid-match" uk-grid="">
        <div class="uk-flex uk-flex-center">
          <div class="uk-container" style="margin-left: 20px">
            <div
              class="uk-grid uk-child-width-1-2@s uk-child-width-1-2"
              uk-grid
            >
              <div class="">
                <img
                  src="./taxa.png"
                  alt="Imagem"
                  style="
                    width: 70%;
                    background-color: #f8f8f8;
                    border-radius: 40px;
                    padding: 10px;
                  "
                />
              </div>
              <h1 class="txt-subTitle">
                <span class="background-text-mb">Melhor taxa </span>do Brasil
              </h1>
            </div>
            <div
              class="uk-grid uk-child-width-1-2@s uk-child-width-1-2 uk-margin-small-top"
              uk-grid
            >
              <div class="">
                <img
                  src="./pig.png"
                  alt="Imagem"
                  style="
                    width: 70%;
                    background-color: #f8f8f8;
                    border-radius: 40px;
                    padding: 10px;
                  "
                />
              </div>
              <h1 class="txt-subTitle-2">
                Parcelas que
                <span class="background-text-mb">cabem no bolso</span>
              </h1>
            </div>
            <div
              class="uk-grid uk-child-width-1-2@s uk-child-width-1-2 uk-margin-small-top"
              uk-grid
            >
              <div class="">
                <img
                  src="./money.png"
                  alt="Imagem"
                  style="
                    width: 70%;
                    background-color: #f8f8f8;
                    border-radius: 40px;
                    padding: 10px;
                  "
                />
              </div>
              <h1 class="txt-subTitle-3">
                Condições<span
                  class="background-text-mb"
                  style="margin-left: 5px"
                  >exclusivas</span
                >
                feitas para você
              </h1>
            </div>
            <div
              class="uk-grid uk-child-width-1-2@s uk-child-width-1-2 uk-margin-small-top"
              uk-grid
            >
              <div class="">
                <img
                  src="./pc.png"
                  alt="Imagem"
                  style="
                    width: 70%;
                    background-color: #f8f8f8;
                    border-radius: 40px;
                    padding: 10px;
                  "
                />
              </div>
              <h1 class="txt-subTitle-3">
                Experiência <br /><span class="background-text-mb"
                  >100% digital</span
                >
              </h1>
            </div>
            <div
              class="uk-grid uk-child-width-1-2@s uk-child-width-1-2 uk-margin-small-top"
              uk-grid
            >
              <div class="">
                <img
                  src="./people.png"
                  alt="Imagem"
                  style="
                    width: 70%;
                    background-color: #f8f8f8;
                    border-radius: 40px;
                    padding: 10px;
                  "
                />
              </div>
              <h1 class="txt-subTitle-4">
                Mais de <span class="background-text-mb"> 30 anos</span> de
                mercado
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" src="./BamaqFeatures.scss"></style>
<script>
export default {
  name: 'FirstComponents',
}
</script>
