<template>
  <section class="background-color-about">
    <div class="uk-container">
      <div
        class="uk-margin-medium-top background-color-sc back-primary mobile-card"
      >
        <div class="uk-padding">
          <h1 class="text-bmq">
            O que as pessoas dizem sobre o Consórcio Bamaq?
            <img class="uk-icon-title" src="./title.png" />
          </h1>
        </div>
      </div>

      <div
        class="uk-slider-container-offset card-mobile margin-mobile-card"
        tabindex="10"
        style="margin-top: -180px"
        uk-slider
      >
        <div
          class="uk-position-relative uk-visible-toggle uk-light"
          tabindex="10"
        >
          <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
          <ul class="uk-slider-items uk-child-width-1-3@s uk-grid">
            <li v-for="testimonial in testimonials" :key="testimonial.id">
              <div class="uk-card-abount uk-card uk-card-default uk-card-size">
                <img class="uk-icon-pp" src="./icon-pp.png" />
                <div>
                  <span class="uk-card-title uk-card-title-1">
                    {{ testimonial.headline }}
                    <img class="uk-start-size" src="./icon_star.png" />
                  </span>
                </div>

                <span class="uk-card-title-2">
                  {{ testimonial.content }}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutBamaq',
  data() {
    return {
      testimonials: [
        {
          id: 1,
          headline: 'Heli Enio',
          content:
            '“Sou proprietário de uma auto escola e queria deixar registrada aqui minha satisfação em negociar com a Bamaq.”',
        },
        {
          id: 2,
          headline: 'Fábio Tadeu',
          content:
            '“Não consigo descrever a sensação de receber a notícia de ter sido contemplado por sorteio no consórcio de imóvel, gostaria de agradecer a Bamaq pela empresa séria que é! Muito obrigado!“',
        },
        {
          id: 3,
          headline: 'Gilberto de Paula',
          content:
            '“Gostaria de agradecer a Bamaq pela minha contemplação, após poucos meses pude efetuar a compra do meu carro.”',
        },
        {
          id: 4,
          headline: 'Jennifer Brenda',
          content:
            'Já estava nos meus planos adquirir meu novo carro, porém não queria pagar as taxas de juros altíssimas do financiamento. Na Bamaq eu encontrei uma forma de comprar com o melhor custo benefício e em menos de 1 ano já estava com meu carro. Por isso indico a todos o consórcio, principalmente se for pela Bamaq. Muito obrigado!',
        },
        {
          id: 5,
          headline: 'Juscelino Braga ',
          content:
            'Quero agradecera Bamaq por ter me proporcionado, através do consórcio de motos, comprar minha CB 500F, sendo contemplado logo no primeiro mês. Valeu pela força Bamaq! É nois!',
        },
        {
          id: 6,
          headline: 'Vitor Gomes',
          content:
            'Gostaria de fazer um agradecimento à Bamaq, que pela segunda vez me ajudou na aquisição de um veículo, de forma ágil e segura. Graças ao consórcio hoje meu novo carro está estacionado na minha garagem.',
        },
      ],
    }
  },
}
</script>

<style lang="scss" src="./About.scss"></style>
