<template>
  <section>
    <div class="uk-container uk-margin-large-top">
      <div class="uk-text-center">
        <span class="text-features-1 font-about-mobile">Sobre</span>
        <span class="text-features-2 font-about-mobile"> o aplicativo 📱</span>
        <div class="uk-hidden@s">
          <img src="./icon-secondary.png" class="" />
        </div>
      </div>

      <div
        class="uk-margin-medium-top uk-grid-small uk-grid-match uk-child-width-1-1@s uk-child-width-1-2@m uk-margin-left uk-margin-size-mobile"
        uk-grid=""
      >
        <div class="uk-grid">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="uk-text-start uk-text-left@m"
          >
            <div class="text-start-mobile">
              <img class="size-icon-digital" src="./icon_bamaq.png" />
              <span class="text-about text-about-mb">{{ item.text }}</span>
            </div>
          </div>
        </div>
        <div class="uk-visible@m">
          <img src="./icon-secondary.png" class="uk-margin-large-left" />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" src="./AboutApp.scss"></style>

<script>
export default {
  name: 'AboutApp',
  data() {
    return {
      items: [
        {
          text: 'Acompanhe sua evolução da cota',
        },
        {
          text: 'Consulte informações do grupo/cota',
        },
        {
          text: 'Oferte lances em vários tipos',
        },
        {
          text: 'Acompanhe as assembleias',
        },
        {
          text: 'Gerencie pagamentos',
        },
        {
          text: 'Verifique seu extrato financeiro',
        },
        {
          text: 'Calcule a chance de contemplação',
        },
        {
          text: 'Processo de contemplação 100% digital',
        },
      ],
    }
  },
}
</script>
